.root {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  min-height: 80vh;
  width: 100%;
}

.info {
  font-size: 2em;
  margin-bottom: 1em;
}

.linkHome {
  display: inline-block;
  text-decoration: none;
  color: #fff;
  background-color: #009654;
  font-size: 1.6em;
  padding: 0.6em;
  border-radius: 4px;
  font-weight: 500;
}
