.root {
  margin: auto;
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em;
  max-width: 80em;
}

.divider {
  width: 100%;
  border: none;
  height: 1px;
  background: #ededf4;
  margin: 3rem 0;
}

.mainText {
  font-size: 1.8em;
  line-height: 1.2em;
  margin-bottom: 2em;
}

.boldText {
  font-weight: 600;
}

.btnAction {
  text-decoration: none;
  color: #fff;
  background-color: #009654;
  font-size: 1.6em;
  padding: 0.8em 1.2em;
  border-radius: 4px;
  font-weight: 600;
  display: inline-block;
}

.linkSection {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.6em;
  flex-wrap: wrap;
}

.linkText {
  margin: 0.5em;
}

.link {
  color: #009654;
}

.note {
  font-size: 1.4em;
  line-height: 1.2em;
  margin-top: 2em;
}
